<template>
    <tr><slot></slot></tr>
</template>

<script>
export default {
  name: 'BaseTableTr'
}
</script>

<style scoped>

</style>
