<template>
  <base-section
    class="section-promotion promotion-kinderedu-garden-pull"
    :default-class="false"
    :default-gup="false">
    <template v-slot:section-background>
      <base-background-item-ellipse/>
      <base-background-item-blot class="bg__blot--right" is-light-blue/>
    </template>

    <h4 class="text--center color-primary">{{$t('PORTAL.PROMOTIONS.KINDEREDU.GARDEN-PULL.TITLE')}}</h4>
    <p class="text--center">{{$t('PORTAL.PROMOTIONS.KINDEREDU.GARDEN-PULL.TEXT.INTRODUCE')}}</p>
    <base-loader  is-relative v-if="isLoading"/>
    <promotions-kinderedu-garden-pull-table-list
                                                 :columns="getColumns"
                                                 :list="getList"/>

  </base-section>
</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import PromotionsKindereduGardenPullTableList
  from '@/components/section/promotion-page/kinderedu/garden-pull/PromotionsKindereduGardenPullTableList'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST,
  KINDEREDU_POPULAR_INSTITUTIONS_ACTION_FETCH
} from '@/store/modules/kinderedu/popular-institutions/kndereduPopularInstitutions.constant'
import { STORE_PATH_KINDEREDU_POPULAR_INSTITUTIONS } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionPromotionKindereduGardenPull',
  components: {
    PromotionsKindereduGardenPullTableList,
    BaseLoader,
    BaseBackgroundItemBlot,
    BaseBackgroundItemEllipse,
    BaseSection
  },
  async created () {
    await this.getItems()
  },
  computed: {
    ...mapGetters(STORE_PATH_KINDEREDU_POPULAR_INSTITUTIONS, [COMMON_LOADER_GETTER_IS_LOADING,
      KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST]),
    isLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    },
    getList () {
      return this[KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST]
    },
    getColumns () {
      const localeMessages = this.$t('PORTAL.PROMOTIONS.KINDEREDU.GARDEN-PULL.TABLE.COLUMNS')
      if (localeMessages.length > 0) {
        return localeMessages.map(item => item.VALUE)
      }
      return ['PORTAL.PROMOTIONS.KINDEREDU.GARDEN-PULL.TABLE.COLUMNS',
        'PORTAL.PROMOTIONS.KINDEREDU.GARDEN-PULL.TABLE.COLUMNS', 'PORTAL.PROMOTIONS.KINDEREDU.GARDEN-PULL.TABLE.COLUMNS']
    }
  },
  methods: {
    ...mapActions(STORE_PATH_KINDEREDU_POPULAR_INSTITUTIONS, [KINDEREDU_POPULAR_INSTITUTIONS_ACTION_FETCH]),
    async getItems () {
      await this[KINDEREDU_POPULAR_INSTITUTIONS_ACTION_FETCH]()
    }
  }
}
</script>

<style scoped>

</style>
