<template>
  <table class="table"
         :class="{'table--primary': isPrimary}"
         :id="uid"
  >
    <thead>
    <slot name="thead">
      <tr>
        <th v-for="(item, index) in columns"
            :key="index"
        >
          {{item}}
        </th>
      </tr>
    </slot>
    </thead>
    <tbody>
    <slot>
      <tr v-for="(item, indexRow) in data" :key="indexRow">
        <td v-for="(itemCol, indexCol) in item"
            :key="`${indexRow}-${indexCol}`">
          {{itemCol}}
        </td>
      </tr>
    </slot>
    </tbody>

  </table>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    isPrimary: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      uid: ''
    }
  },
  mounted () {
    this.uid = `table-${this._uid}`
    this.applyCss()
  },
  beforeUpdate () {
    this.applyCss()
  },
  methods: {
    /**
     * Преобразует cols для стилизации таблицы в мобильном разрешение
     */
    applyCss () {
      const nodeId = `table-content-${this.uid}`
      let style = document.getElementById(nodeId)
      if (!style) {
        style = document.createElement('style')
        style.id = nodeId
        this.$el.appendChild(style)
      }
      const rules = this.columns.map((item, index) => {
        return `#${this.uid} td:nth-of-type(${index + 1}):before { content: "${item}" !important; }`
      })

      style.innerHTML = `@media screen and (max-width: 768px) {  ${rules.join(' ')} }`
    }
  }
}
</script>

<style scoped>

</style>
