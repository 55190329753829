<template>

    <div>
      <section-head/>
      <section-promotion-kinderedu-main/>
      <section-promotion-kinderedu-services-about/>
      <section-promotion-kinderedu-functional/>
      <section-promotion-kinderedu-garden-pull/>
      <section-promotion-kinderedu-footer/>
      <section-footer/>
    </div>
</template>

<script>
import SectionPromotionKindereduFooter
  from '@/components/section/promotion-page/kinderedu/SectionPromotionKindereduFooter'
import SectionPromotionKindereduFunctional
  from '@/components/section/promotion-page/kinderedu/SectionPromotionKindereduFunctional'
import SectionPromotionKindereduGardenPull
  from '@/components/section/promotion-page/kinderedu/SectionPromotionKindereduGardenPull'
import SectionPromotionKindereduMain from '@/components/section/promotion-page/kinderedu/SectionPromotionKindereduMain'
import SectionPromotionKindereduServicesAbout
  from '@/components/section/promotion-page/kinderedu/SectionPromotionKindereduServicesAbout'
import SectionFooter from '@/components/section/SectionFooter'
import SectionHead from '@/components/section/SectionHead'
export default {
  name: 'ViewPromotionKinderedu',
  components: { SectionPromotionKindereduFooter, SectionPromotionKindereduGardenPull, SectionFooter, SectionPromotionKindereduFunctional, SectionPromotionKindereduServicesAbout, SectionPromotionKindereduMain, SectionHead }
}
</script>
<style src="@/assets/scss/promotions/promotions.scss"
       lang="scss"></style>
<style src="@/assets/scss/promotions/promotions-kinderedu/promotion-kinderedu.scss"
       lang="scss"></style>
