<template>
  <base-section class="section-promotion promotion-kinderedu-functional"
                :default-gup="false"
                :default-class="false">
    <template v-slot:section-background>
      <base-background-item-triangle class="bg__triangle--top"/>
      <base-background-item-triangle is-green class="bg__triangle--right"/>
      <base-background-item-astro/>
      <base-background-item-oval class="bg__oval--planet-one" is-light-blue/>
      <base-background-item-oval class="bg__oval--planet-two" is-shine-green/>
      <base-background-item-oval class="bg__oval--bottom" is-shine-green/>
      <base-background-item-ellipse class="bg__ellipse--bottom-left"/>
      <base-background-item-blot/>
      <base-background-item-square/>
      <base-background-item-ellipse is-green class="bg__ellipse--bottom-center"/>
    </template>

    <h4 class="color-primary text--center">
      {{$t('PORTAL.PROMOTIONS.KINDEREDU.FUNCTIONAL.TITLE')}}
    </h4>
    <p class="text--center">
      {{$t('PORTAL.PROMOTIONS.KINDEREDU.FUNCTIONAL.TEXT.INTRODUCE')}}
    </p>
    <promotions-kinderedu-functional-list :list="getFunctionalList"/>

    <p class="text--center">
        {{$t('PORTAL.PROMOTIONS.KINDEREDU.FUNCTIONAL.TEXT.ABOUT-APP')}}
    </p>
  </base-section>
</template>

<script>
import BaseBackgroundItemAstro from '@/components/base/background/item/BaseBackgroundItemAstro'
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseBackgroundItemSquare from '@/components/base/background/item/BaseBackgroundItemSquare'
import BaseBackgroundItemTriangle from '@/components/base/background/item/BaseBackgroundItemTriangle'
import BaseSection from '@/components/base/section/BaseSection'
import PromotionsKindereduFunctionalList
  from '@/components/section/promotion-page/kinderedu/section-functional/PromotionsKindereduFunctionalList'

export default {
  name: 'SectionPromotionKindereduFunctional',
  components: {
    PromotionsKindereduFunctionalList,
    BaseBackgroundItemSquare,
    BaseBackgroundItemBlot,
    BaseBackgroundItemEllipse,
    BaseBackgroundItemOval,
    BaseBackgroundItemAstro,
    BaseBackgroundItemTriangle,
    BaseSection
  },
  computed: {
    getFunctionalList () {
      return this.$t('PORTAL.PROMOTIONS.KINDEREDU.FUNCTIONAL.LIST.FUNCTION', [])
    }
  }
}
</script>

<style scoped>

</style>
