<template>
    <base-section class="section-promotion promotion-kinderedu-about">
      <template slot="section-background">
        <base-background-item-blot class="bg__blot-left"/>
        <base-background-item-blot class="bg__blot-right-bottom"/>
        <base-background-item-square class="bg__square--right-bottom"/>
        <base-background-item-ellipse class="bg__ellipse--left"/>
        <base-background-item-ellipse class="bg__ellipse--right"/>
        <base-background-item-square class="bg__square--left"/>
      </template>
      <p class="text--center">
        {{$t('PORTAL.PROMOTIONS.KINDEREDU.ABOUT.TEXT.INTRODUCE')}}
             </p>
      <h4 class="color-primary text--center">
        {{$t('PORTAL.PROMOTIONS.KINDEREDU.ABOUT.HEADER.SERVICE')}}
      </h4>
      <div class="content-row promotion-kinderedu-about__first-row">
        <div class="content-row__main">
          <img src="/assets/img/promotions/about-first.jpg" alt=""/>
        </div>
        <div class="content-row__aside">
          <p>
            {{$t('PORTAL.PROMOTIONS.KINDEREDU.ABOUT.TEXT.NORMATIVE-MATCH')}}
          </p>
          <promotions-kinderedu-service-about-documents-list/>
        </div>
      </div>

      <div class="content-row promotion-kinderedu-about__second-row">
        <div class="content-row__main">
          <p>{{$t('PORTAL.PROMOTIONS.KINDEREDU.ABOUT.TEXT.PROVIDES-SERVICE')}}</p>
          <base-bold-dot-list>
            <base-bold-dot-list-item v-for="(item, index) in getProvidesServiceList"
                                     :key="index">
              {{item.VALUE}}
            </base-bold-dot-list-item>
          </base-bold-dot-list>
        </div>
        <div class="content-row__aside">
          <img src="/assets/img/promotions/about-second.jpg" alt=""/>
        </div>
      </div>
    </base-section>
</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseBackgroundItemSquare from '@/components/base/background/item/BaseBackgroundItemSquare'
import BaseBoldDotList from '@/components/base/boldDotList/BaseBoldDotList'
import BaseBoldDotListItem from '@/components/base/boldDotList/BaseBoldDotListItem'
import BaseSection from '@/components/base/section/BaseSection'
import PromotionsKindereduServiceAboutDocumentsList
  from '@/components/section/promotion-page/kinderedu/serivce-about/PromotionsKindereduServiceAboutDocumentsList'
export default {
  name: 'SectionPromotionKindereduServicesAbout',
  components: {
    BaseBoldDotListItem,
    BaseBoldDotList,
    PromotionsKindereduServiceAboutDocumentsList,
    BaseBackgroundItemEllipse,
    BaseBackgroundItemSquare,
    BaseBackgroundItemBlot,
    BaseSection
  },
  computed: {
    getProvidesServiceList () {
      const items = this.$t('PORTAL.PROMOTIONS.KINDEREDU.ABOUT.LIST.PROVIDES-SERVICE')
      if (typeof items !== 'object') {
        return []
      }
      return items
    }
  }
}
</script>

<style scoped>

</style>
