<template>
  <section class="section-promotion promotion-kinderedu">
    <section-main-backgrounds
      :loading="false"
      :list="getBgList"></section-main-backgrounds>
    <div class="promotion-kinderedu__container">
      <div class="overlay"></div>
      <div class="content">
        <h1 class="title">{{$t('PORTAL.PROMOTIONS.KINDEREDU.MAIN.TITLE')}}</h1>
        <p class="description">{{$t('PORTAL.PROMOTIONS.KINDEREDU.MAIN.DESCRIPTION')}}</p>
      </div>

    </div>
  </section>

</template>

<script>
import SectionMainBackgrounds from '@/components/section/main/SectionMainBackgrounds'
export default {
  name: 'SectionPromotionKindereduMain',
  components: { SectionMainBackgrounds },
  computed: {
    getBgList () {
      return [{
        url: '../../../assets/img/promotions/main-slide.jpg',
        id: `bg-promo-${Math.round(Math.random() * 1000)}`
      }]
    }
  }
}
</script>

<style scoped>

</style>
