import { render, staticRenderFns } from "./BaseTableTd.vue?vue&type=template&id=71d6e354&scoped=true&"
import script from "./BaseTableTd.vue?vue&type=script&lang=js&"
export * from "./BaseTableTd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d6e354",
  null
  
)

export default component.exports