<template>
      <base-bold-dot-list-item>
        <base-button is-link
                     :href="getUrl">
          {{value.name}}
        </base-button>
      </base-bold-dot-list-item>
</template>

<script>
import BaseBoldDotListItem from '@/components/base/boldDotList/BaseBoldDotListItem'
import BaseButton from '@/components/base/button/BaseButton'
export default {
  name: 'PromotionsKindereduServiceAboutDocumentsListItem',
  components: { BaseButton, BaseBoldDotListItem },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          file: undefined,
          name: undefined
        }
      }
    }
  },
  computed: {
    getUrl () {
      return this.value?.file?.url
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
