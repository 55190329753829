<template>
  <div class="promo-svg-item">
    <svg-item :src="getSrc"></svg-item>
  </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'

export default {
  name: 'BaseIconPromo',
  components: { SvgItem },
  props: {
    type: {
      type: String,
      default: 'kinderedu'
    },
    value: {
      type: String
    }
  },
  computed: {
    getSrc () {
      return `/assets/img/promotions/${this.type}/${this.value}.svg`
    }
  }
}
</script>

<style scoped>

</style>
