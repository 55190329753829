<template>
    <base-table :columns="columns">
      <promotions-kinderedu-garden-pull-table-list-item
        v-for="item in list"
        :key="item.id"
        :value="item"/>
    </base-table>
</template>

<script>
import BaseTable from '@/components/base/table/BaseTable'
import PromotionsKindereduGardenPullTableListItem
  from '@/components/section/promotion-page/kinderedu/garden-pull/PromotionsKindereduGardenPullTableListItem'
export default {
  name: 'PromotionsKindereduGardenPullTableList',
  components: { PromotionsKindereduGardenPullTableListItem, BaseTable },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
