<template>
  <base-card-list class="promotion-kinderedu-functional__list">
      <promotions-kinderedu-functional-list-item v-for="(item, index) in list"
                                                 :value="item"
                                                 :key="index"
      />
  </base-card-list>
</template>

<script>
import BaseCardList from '@/components/base/card/BaseCardList'
import PromotionsKindereduFunctionalListItem
  from '@/components/section/promotion-page/kinderedu/section-functional/PromotionsKindereduFunctionalListItem'
export default {
  name: 'PromotionsKindereduFunctionalList',
  components: { PromotionsKindereduFunctionalListItem, BaseCardList },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
