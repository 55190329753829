<template>
  <base-table-tr>
    <base-table-td>{{value.shortname}}</base-table-td>
    <base-table-td>{{getCity}}</base-table-td>
    <base-table-td><base-button is-link
                                :href="getUrl">
      <span class="value">{{getUrl}}</span>
      <span class="short-value">ссылка</span>
    </base-button></base-table-td>
  </base-table-tr>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import BaseTableTd from '@/components/base/table/BaseTableTd'
import BaseTableTr from '@/components/base/table/BaseTableTr'
import { C_CONTACTS_TYPE_URL } from '@/lib/constant/c.contacts'
export default {
  name: 'PromotionsKindereduGardenPullTableListItem',
  components: { BaseButton, BaseTableTd, BaseTableTr },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          shortname: undefined,
          address: undefined,
          contacts: []
        }
      }
    }
  },
  computed: {
    getCity () {
      return this.value?.address?.city
    },
    getUrl () {
      const contacts = this.value.contacts?.filter(item => item.type === C_CONTACTS_TYPE_URL)
      if (contacts.length === 0) {
        return ''
      }
      return contacts.pop()?.value
    }
  }
}
</script>

<style scoped>

</style>
