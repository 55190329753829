<template>
    <td><slot></slot></td>
</template>

<script>
export default {
  name: 'BaseTableTd'
}
</script>

<style scoped>

</style>
