<template>
  <base-section class="section-promotion promotion-kinderedu-footer"
                :default-class="false"
                :default-gup="false">
    <h4 class="color-primary text--center">{{$t('PORTAL.PROMOTION.KINDEREDU.FOOTER.TITLE')}}</h4>
    <p class="text--center">{{$t('PORTAL.PROMOTION.KINDEREDU.FOOTER.TEXT.INTRODUCE')}}</p>

    <div class="promotion-kinderedu-footer__contacts">
      <h4 class="color-primary">{{$t('PORTAL.PROMOTION.KINDEREDU.FOOTER.CONTACT.TITLE')}}</h4>
      <persona-content :member="getContactMember"></persona-content>

    </div>
  </base-section>
</template>

<script>
import BaseSection from '@/components/base/section/BaseSection'
import PersonaContent from '@/components/misc/content/PersonaContent'
import { C_CONTACTS_TYPE_MOBILE_OFFICE } from '@/lib/constant/c.contacts'

export default {
  name: 'SectionPromotionKindereduFooter',
  components: {
    PersonaContent,
    BaseSection
  },
  CONTACT_PHONE: C_CONTACTS_TYPE_MOBILE_OFFICE,
  computed: {
    getContactMember () {
      /**
       * @var {{SURNAME,FIRSTNAME,POSITION}}
       */
      const memberInfo = this.$t('PORTAL.PROMOTION.KINDEREDU.FOOTER.PERSONA.MEMBER.VALUE', {})
      const member = {}
      for (const memberInfoKey in memberInfo) {
        member[memberInfoKey.toLowerCase()] = memberInfo[memberInfoKey]
      }
      return {
        ...member,
        contacts: [{
          id: 0,
          type: C_CONTACTS_TYPE_MOBILE_OFFICE,
          value: this.$t('PORTAL.PROMOTION.KINDEREDU.PERSONA.MEMBER.CONTACT.VALUE', 0)
        }]

      }
    }
  }
}
</script>

<style scoped>

</style>
