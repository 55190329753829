<template>
    <ul class="bold-dot-list"
      :class="{'bold-dot-list--primary': !accent,
               'bold-dot-list--accent': accent
      }"
    >
      <slot></slot>
    </ul>
</template>

<script>
export default {
  name: 'BaseBoldDotList',
  props: {
    accent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
