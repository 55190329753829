<template>
    <base-card is-vertical>
    <base-icon-promo :value="getIcon"
                     :type="getIconType"
                     slot="card-logo"/>
      <h5>{{getTitle}}</h5>
      <span>{{getDescription}}</span>
    </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseIconPromo from '@/components/base/icon/BaseIconPromo'
export default {
  name: 'PromotionsKindereduFunctionalListItem',
  components: { BaseIconPromo, BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          TITLE: undefined,
          ICON: undefined,
          'ICON-TYPE': undefined,
          DESCRIPTION: undefined
        }
      }
    }
  },
  computed: {
    getTitle () {
      return this.value?.TITLE
    },
    getIcon () {
      return this.value?.ICON
    },
    getIconType () {
      return this.value['ICON-TYPE']
    },
    getDescription () {
      return this.value?.DESCRIPTION
    }
  }
}
</script>

<style scoped>

</style>
