<template>
  <base-bold-dot-list class="promotion-kinderedu__normative-documents">
    <base-loader v-if="isLoading"/>
    <promotions-kinderedu-service-about-documents-list-item
      v-else
      v-for="item in getList"
      :key="item.id"
      :value="item"/>
  </base-bold-dot-list>
</template>

<script>
import BaseBoldDotList from '@/components/base/boldDotList/BaseBoldDotList'
import BaseLoader from '@/components/base/loader/BaseLoader'
import PromotionsKindereduServiceAboutDocumentsListItem
  from '@/components/section/promotion-page/kinderedu/serivce-about/PromotionsKindereduServiceAboutDocumentsListItem'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST,
  SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS
} from '@/store/modules/system/system.constant'
import { STORE_PATH_SYSTEM } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PromotionsKindereduServiceAboutDocumentsList',
  components: {
    PromotionsKindereduServiceAboutDocumentsListItem,
    BaseBoldDotList,
    BaseLoader
  },
  async created () {
    await this.getItems()
  },
  computed: {
    ...mapGetters(STORE_PATH_SYSTEM, [SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST, COMMON_LOADER_GETTER_IS_LOADING]),
    isLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    },
    getList () {
      return this[SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST]
    }
  },
  methods: {
    ...mapActions(STORE_PATH_SYSTEM, [SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS]),
    async getItems () {
      return await this[SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS]()
    }
  }
}
</script>

<style scoped>

</style>
